// Framework dependencies
import { useCallback, useMemo } from 'react';
import Axios from 'axios';
import { ItemKinds } from 'types';

// Local dependencies
import { useServerProps } from 'utils/ServerProps';

import useSWR from 'swr';
import { useRESTClient } from 'context';
import { useSWRWhenAuthed } from 'utils/use-swr-when-authed';
import { ItemStatuses } from '../../../constants';

import {
  propertyAddressForRates,
  formatCheckoutData,
  formatItemsMetaData,
} from './format';
import { PaymentCadenceKey, SureFlow } from '../types';

const SURE_PREFIX = 'protections/renters';
const SURE_CHECKOUT = 'checkout';

const useSureClient = (version = 'v1.1') => {
  const {
    sureApi: { url, token },
  } = useServerProps();
  const apiUrl = `${url}/${version}/`;
  return useMemo(
    () =>
      Axios.create({
        baseURL: apiUrl,
        headers: {
          authorization: token,
        },
      }),
    [apiUrl, token]
  );
};

/**
 * @method useItemDefinitions
 * @description fetch item definitions from the updater api.
 * @returns {Object}
 */
export const useItemDefinitions = () => {
  const PROVIDER_ID = 24;
  const { data: itemDefinitionsData, error: itemDefinitionsError } =
    useSWRWhenAuthed('/v2/item_definitions');

  let itemDefinitions = {
    data: null,
    error: itemDefinitionsError,
  };

  if (itemDefinitionsData) {
    const providersList = itemDefinitionsData.filter(
      (elem) => elem.id === PROVIDER_ID
    );

    itemDefinitions = {
      data: providersList[0]?.page,
      error: null,
    };
  }

  return itemDefinitions;
};

/**
 * @method useItemData
 * @description Get existing item state
 * @returns {Object[]} Array of insurance saved items
 */
export const useItemData = () => {
  const { axios } = useRESTClient();
  const getItemData = useCallback(
    () =>
      axios
        .get('/v2/items')
        .then((res: { data: any }) => res.data)
        .then((data: any[]) =>
          data.filter(
            (form: { type: string }) => form.type === ItemKinds.INSURANCE
          )
        ),
    [axios]
  );

  return {
    getItemData,
  };
};

/**
 * @callback setItemData
 * @param {Object} userData the current user data object
 * @param {ItemStatuses} status the item status
 */

/**
 * @method useSetItemData
 * @description set item data in the updater api.
 * @returns {setItemData}
 */
export const useSetItemData = () => {
  const { axios } = useRESTClient();
  const setItemData = useCallback(
    (userData: Partial<SureFlow>, status: ItemStatuses) => {
      return axios
        .post('/v2/items', formatItemsMetaData(userData, status))
        .then((res: { data: any }) => res?.data);
    },
    [axios]
  );

  return {
    setItemData,
  };
};

/**
 * @method useSurePlans
 * @description Post for the Sure API plans endpoint.
 * @param {Object} property_address the current property_address data.
 * @returns {Object}
 */
export const useSurePlans = (property_address) => {
  const client = useSureClient();

  return useSWR(
    property_address && Object.keys(property_address).length > 0
      ? `sure-plans-${JSON.stringify(property_address)}`
      : null,
    () => {
      return client
        .post(`${SURE_PREFIX}/plans`, { property_address })
        .then((res: { data: any }) => res.data);
    }
  );
};

/**
 * @callback getRates
 * @param {Object} userData the current user data object
 */

/**
 * @method useFetchRates
 * @description Post for the Sure API rates endpoint.
 * @returns {getRates} callback
 */
export const useFetchRates = () => {
  const client = useSureClient();

  const getRates = useCallback(
    (userData) => {
      const payload = propertyAddressForRates(userData);

      return client
        .post(`${SURE_PREFIX}/rates`, payload)
        .then((res: { data: any }) => res.data);
    },
    [client]
  );

  return {
    getRates,
  };
};

/**
 * @callback checkout
 * @param {string} userData the user data object
 */

/**
 * @method useCheckout
 * @description Post for the Sure API checkout endpoint.
 * @returns {checkout} callback
 */
export const useCheckout = () => {
  const client = useSureClient();

  const checkout = useCallback(
    (userData) => {
      const body = formatCheckoutData(userData);
      return Promise.all([
        client
          .post(`${SURE_PREFIX}/${SURE_CHECKOUT}`, {
            ...body,
            payment_cadence: PaymentCadenceKey.ANNUALLY,
          })
          .then((res: { data: any }) => res.data),
        client
          .post(`${SURE_PREFIX}/${SURE_CHECKOUT}`, {
            ...body,
            payment_cadence: PaymentCadenceKey.MONTHLY,
          })
          .then((res: { data: any }) => res.data),
      ]);
    },
    [client]
  );

  return {
    checkout,
  };
};

/**
 * @callback submitPaymentMethod
 * @param {string} stripeToken the authorized stripe token
 * @param {string} email current user email
 * @param {string} firstName current user fist name
 * @param {string} lastName current user last name
 */

/**
 * @method usePaymentMethod
 * @description Post for submitting payment method/ stripe endpoint.
 * @returns {submitPaymentMethod} callback
 */
export const usePaymentMethod = () => {
  const client = useSureClient('v1');

  const submitPaymentMethod = useCallback(
    (stripeToken, email, firstName, lastName) => {
      return client
        .post('methods', {
          first_name: firstName,
          last_name: lastName,
          email,
          token: stripeToken,
        })
        .then((res: { data: any }) => res.data);
    },
    [client]
  );
  return {
    submitPaymentMethod,
  };
};

/**
 * @callback completePurchase
 * @param {object} userData the user data object
 * @param {string} paymentMethodId the returned payment method
 */

/**
 * @method useCompletePurchase
 * @description Post for the Sure API purchase endpoint.
 * @returns {completePurchase} callback
 */
export const useCompletePurchase = () => {
  const client = useSureClient();

  const completePurchase = useCallback(
    (userData, paymentMethodId) => {
      return client
        .post(`${SURE_PREFIX}/purchase`, {
          plan_id: userData.plan_id,
          quote_id: userData.quote_id,
          payment_method_id: paymentMethodId,
          payment_cadence: userData.payment_cadence,
        })
        .then((res: { data: any }) => res.data);
    },
    [client]
  );
  return {
    completePurchase,
  };
};

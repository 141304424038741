/* eslint-disable @typescript-eslint/naming-convention */
// Local dependencies
import { formatDate } from 'utils/date';
import { ItemKinds } from 'types';

import { ADDRESS_FIELD_MODEL, TRUE_STRING, FALSE_STRING } from '../constants';

/**
 * @method makeTomorrowsDate
 * @description create a date object with tomorrow's date
 * @returns {Object}
 */
export const makeTomorrowsDate = () =>
  formatDate(new Date().setDate(new Date().getDate() + 1));

const thirdPartyAddress = (userData) => {
  const { has_intrested_party, intrested_party_name, intrested_party_address } =
    userData;

  if (has_intrested_party === FALSE_STRING) {
    return {
      intrested_party_name: null,
      intrested_party_address_city: null,
      intrested_party_address_line1: null,
      intrested_party_address_line2: null,
      intrested_party_address_postal: null,
      intrested_party_address_state: null,
    };
  }

  return {
    intrested_party_name: intrested_party_name || null,
    intrested_party_address_city: intrested_party_address?.city || null,
    intrested_party_address_line1: intrested_party_address?.street || null,
    intrested_party_address_line2: intrested_party_address?.unit || null,
    intrested_party_address_postal: intrested_party_address?.postalCode || null,
    intrested_party_address_state: intrested_party_address?.state || null,
  };
};

const otherPersonName = (userData) => {
  const { has_sni, sni_first_name, sni_last_name } = userData;

  if (has_sni === 'false') {
    return {
      sni_first_name: null,
      sni_last_name: null,
    };
  }

  return {
    sni_first_name: sni_first_name || null,
    sni_last_name: sni_last_name || null,
  };
};

const pickAddress = (policyAddressList, toAddress) => {
  const addressList = Object.values(policyAddressList || {});
  return addressList.length
    ? addressList.filter((elem: any) => elem?.selected)[0]
    : toAddress;
};

const policyPapersAddress = (userData) => {
  const { policyAddressList, toAddress } = userData;
  const pickAddressData = pickAddress(policyAddressList, toAddress);

  return {
    mailing_address_city: pickAddressData?.city,
    mailing_address_line1: pickAddressData?.street,
    mailing_address_line2: pickAddressData?.unit,
    mailing_address_postal: pickAddressData?.postalCode,
    mailing_address_state: pickAddressData?.state,
  };
};

const propertyAddress = (userData) => {
  const { policyAddressList, toAddress } = userData;
  const pickAddressData = pickAddress(policyAddressList, toAddress);

  return {
    city: pickAddressData?.city,
    street_address: pickAddressData?.street,
    unit: pickAddressData?.unit,
    postal: pickAddressData?.postalCode,
    region: pickAddressData?.state,
    country_code: 'US',
  };
};

const setBooleanStringToBoolean = (userData) => {
  return Object.keys(userData).reduce((acc, key) => {
    const value = userData[key];
    const isBooleanValue = value === TRUE_STRING || value === FALSE_STRING;

    if (isBooleanValue) {
      acc[key] = value === TRUE_STRING;
    }

    return acc;
  }, {});
};

const makeDetails = (userData) => {
  const {
    construction_type,
    dwelling_type,
    loss_date,
    number_of_losses,
    pni_first_name,
    pni_last_name,
    pni_phone_number,
  } = userData;

  return {
    ...setBooleanStringToBoolean(userData),
    construction_type,
    dwelling_type: dwelling_type || null,
    loss_date: loss_date ? formatDate(new Date(loss_date)) : '',
    number_of_losses,
    pni_first_name,
    pni_last_name,
    pni_phone_number,
    mailing_address: policyPapersAddress(userData),
    mandatory_insurance_requirement: true,
    ...otherPersonName(userData),
    ...thirdPartyAddress(userData),
  };
};

/**
 * @method propertyAddressForRates
 * @param {Object} userData The current SYI state data.
 * @description Return the property_address data to set on the sure endpoint rates as parameter.
 * @returns {Object}
 */
export const propertyAddressForRates = (userData) => {
  const { plan_id } = userData;

  return {
    plan_id,
    settings: {
      policy_effective_date: makeTomorrowsDate(),
    },
    details: {
      ...makeDetails(userData),
      ...policyPapersAddress(userData),
    },
    property_address: propertyAddress(userData),
  };
};

/**
 * @method formatRatesReturnToState
 * @param {Object} payload from rates
 * @description formats the defaults and options returned from the rates endpoint
 * @returns {Object}
 */
export const formatRatesReturnToState = (payload) => {
  const {
    quote_id,
    rates: {
      defaults: {
        all_peril_deductible,
        personal_property_coverage,
        hurricane_deductible,
        liability_limit,
        medical_limit = null,
        include_identity_fraud = false,
        include_pet_damage = false,
        include_replacement_cost = false,
        include_water_backup = false,
        include_earthquake = false,
      },
    },
  } = payload;

  return {
    personal_property_coverage,
    all_peril_deductible,
    hurricane_deductible,
    liability_limit,
    medical_limit,
    include_pet_damage,
    include_replacement_cost,
    include_water_backup,
    include_identity_fraud,
    include_earthquake,
    quote_id,
    ratesResponse: payload,
  };
};

/**
 * @method formatPurchaseReturnToState
 * @param {Object} payload from from payment method endpoint
 * @description formats the response from the payment method endpoint
 * @returns {Object}
 */
export const formatPurchaseReturnToState = (payload) => {
  const { agreement_id, status_code, policy_number } = payload;

  return {
    agreement_id,
    status_code,
    policy_number,
    purchaseResponse: payload,
    payment_date: formatDate(new Date()),
  };
};

/**
 * @method formatCheckoutData
 * @param {Object} userData The current SYI state data.
 * @description Return the property_address data to set on the sure endpoint rates as parameter.
 * @returns {Object}
 */
export const formatCheckoutData = (userData) => {
  const {
    plan_id,
    quote_id,
    policy_effective_date,
    personal_property_coverage,
    all_peril_deductible,
    hurricane_deductible,
    liability_limit,
    pni_email,
    include_replacement_cost,
    include_water_backup,
    include_identity_fraud,
    include_earthquake,
    include_pet_damage,
  } = userData;
  return {
    plan_id,
    quote_id,
    email: pni_email,
    settings: {
      policy_effective_date,
      personal_property_coverage,
      all_peril_deductible,
      hurricane_deductible,
      liability_limit,
      include_replacement_cost,
      include_water_backup,
      include_identity_fraud,
      include_earthquake,
      include_pet_damage,
    },
    details: {
      ...makeDetails(userData),
    },
    property_address: propertyAddress(userData),
  };
};

/**
 * @method formatItemsMetaData
 * @param {Object} userData The current SYI state data.
 * @param {Object} status The current items status.
 * @description Return the property_address data to set on the sure endpoint rates as parameter.
 * @returns {Object}
 */
export const formatItemsMetaData = (userData, status) => {
  // Pull out cardInfo from userData to not store
  const { move_id, name_id } = userData;
  return {
    move_id: parseInt(move_id, 10),
    name_id: parseInt(name_id, 10),
    kind: ItemKinds.INSURANCE,
    status,
    metadata: userData || {},
  };
};

/**
 * @method addressDataToValidate
 * @description Filters an address data without the unit optional key/value.
 * @param {Object} addressData The address data object
 * @returns {Object}
 */
export const addressDataToValidate = (addressData) => {
  const actualAddressData = {
    ...ADDRESS_FIELD_MODEL,
    ...addressData,
  };
  const addressFiltered = Object.entries(actualAddressData).filter(([key]) => {
    return key !== 'unit';
  });

  return Object.fromEntries(addressFiltered);
};

/**
 * @method isPartyAddressValid
 * @description Check is an address field is valid.
 * @param {Object} addressField The address form dictionary.
 * @returns {boolean}
 */
export const isPartyAddressValid = (addressField: {
  [key: string]: string;
}) => {
  const sixNumbersRegex = new RegExp('\\d{5}');
  const addressData = addressDataToValidate(addressField);
  const zipCode = addressData.postalCode.toString();

  return (
    Object.values(addressData).every((value) => value) &&
    sixNumbersRegex.test(zipCode)
  );
};
